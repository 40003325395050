<template>
  <div class="contents detail-info">
    <div class="contents__items contents__header">
      <h2>
        <i class="ico--users"></i>
        {{ translate(translations, 'bet history details') }}
      </h2>
    </div>

    <div class="contents__items contents__body">
      <div class="wrap">
        <div class="wrap__contents wrap__contents-main">
          <div class="wrap__inner overflow-y">
            <bet-list-new v-if="$route.params.details === 'details'" />

            <bet-list-transaction v-if="$route.params.details === 'transaction'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//= filters
import FormatAmount from '@/assets/js/filters/FormatAmount'
import Status from '@/assets/js/filters/Status'

import BetListNew from '@/views/bet-history/BetListNew'
import BetListTransaction from '@/views/bet-history/BetListTransaction'

//= translation
import { translate } from '@/assets/js/translations/Translate'

// Mixins
import { translations } from '@/assets/js/translations/UserDetail'

export default {
  name: 'BetHistoryDetails',

  filters: {
    FormatAmount,
    Status
  },

  components: {
    BetListNew,
    BetListTransaction
  },

  mixins: [
    translate
  ],

  data () {
    return {
      translations
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-y {
  overflow-y: auto !important;
}
</style>
